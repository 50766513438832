import { DzButtonNew, MEDIA_ASPECT_RATIOS, useIsSmallWindowSize } from '@zwirner/design-system'
import Image from 'next/image'
import Link from 'next/link'
import React, { useEffect, useRef, useState } from 'react'

import { dzMediaMapper } from '@/common/utilsMappers/image.mapper'
import { DzMedia } from '@/components/wrappers/DzMediaWrapper'
import type { MediaBuilderSchemaType } from '@/sanity/queries/components/builders/mediaBuilder'
import cn from '@/utils/cn'

type GalleryLandingProps = {
  elements: {
    title: string
    link: {
      href: string
      blank: boolean
    }
    media: MediaBuilderSchemaType
    _key: string
  }[]
}
const suffix = '-dzlanding-img'

export const getBufferSpace = (landingOffsetTop: number, containerHeight: number) => {
  const buffer = containerHeight - landingOffsetTop

  if (window.innerHeight > buffer) return window.innerHeight - buffer
  return 0
}

// Fix flickering issue on Firefox
export const fixHeaderPosition = (container: HTMLElement, revert = false) => {
  const header = container.querySelector('header') as HTMLElement

  if (revert) {
    header.style.position = ''
    container.style.paddingTop = ''
    return
  }
  header.style.position = 'fixed'
  container.style.paddingTop = header.getBoundingClientRect().height + 'px'
}

export const GalleryLanding = ({ elements }: GalleryLandingProps) => {
  const [activeImgId, setActiveImgId] = useState<string>(elements[0]._key)
  const landingContainer = useRef<HTMLDivElement>(null)
  const slides = useRef<HTMLDivElement>(null)

  const isMobile = useIsSmallWindowSize()
  useEffect(() => {
    if (isMobile) return
    const landingContainerElement = landingContainer.current as HTMLElement

    const images = landingContainerElement.querySelectorAll(`[id$="${suffix}"]`)

    const observerOptions: IntersectionObserverInit = {
      rootMargin: '-50% 0px',
      threshold: 0,
      root: slides.current,
    }

    const observer = new IntersectionObserver((entries) => {
      if (entries.length === elements.length) return
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveImgId(entry.target.id.replace(suffix, ''))
        }

        if (!entry.isIntersecting) {
          const previousSibling = entry.target.previousElementSibling as HTMLElement
          const previousSiblingId = previousSibling?.id.replace(suffix, '')
          setActiveImgId(previousSiblingId)
        }
      })
    }, observerOptions)

    images.forEach((section) => {
      observer.observe(section)
    })

    return () => {
      images.forEach((section) => {
        observer.unobserve(section)
      })
    }
  }, [elements, isMobile])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      slides.current?.scrollTo(0, window.scrollY - (landingContainer.current?.offsetTop || 0) - 70)
    })
  }, [])

  useEffect(() => {
    const landingContainerElement = landingContainer.current as HTMLElement
    const slidesElement = slides.current as HTMLElement

    const globalContainer = document.querySelector('#__next') as HTMLElement
    const globalContainerChild = globalContainer.firstChild as HTMLElement
    const isFirefox = navigator.userAgent.toLowerCase().includes('firefox')
    if (!globalContainer) throw new Error('Main container "#__next" not found')

    const revertDomChanges = () => {
      globalContainer.style.height = ''
      globalContainerChild.style.position = ''
      globalContainerChild.style.top = ''
      landingContainerElement.style.marginBottom = ''

      if (isFirefox) {
        fixHeaderPosition(globalContainerChild, true)
      }
    }

    const onResize = () => {
      // In case it is being used in Gallery detail pages
      const noLandingVisible = ![
        ...document.querySelectorAll<HTMLElement>('[data-gallery-landing]'),
      ].some((e) => e.offsetParent !== null)

      if (isMobile || noLandingVisible) {
        return revertDomChanges()
      }

      if (landingContainerElement.offsetParent == null) return

      const allSlidesHeight = slidesElement.getBoundingClientRect().height * (elements.length - 1)
      const globalContainerChildHeight =
        globalContainerChild.getBoundingClientRect().height -
        parseFloat(getComputedStyle(landingContainerElement).marginBottom)

      const buffer = getBufferSpace(landingContainerElement.offsetTop, globalContainerChildHeight)
      globalContainer.style.height =
        allSlidesHeight + globalContainerChildHeight + buffer + 120 + 'px'

      globalContainerChild.style.position = 'sticky'
      globalContainerChild.style.top = `-${landingContainerElement.offsetTop}px`
      landingContainerElement.style.marginBottom = buffer + 'px'

      if (isFirefox) {
        fixHeaderPosition(globalContainerChild)
      }
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === globalContainerChild) {
          onResize()
        }
      }
    })

    resizeObserver.observe(globalContainerChild)
    onResize()

    return () => {
      resizeObserver.unobserve(globalContainerChild)
      resizeObserver.disconnect()
      revertDomChanges()
    }
  }, [elements, isMobile])

  const onMouseHover = (elIndex: number) => {
    if (isMobile) return

    if (Math.abs((landingContainer.current?.getBoundingClientRect().top || 0) - 70) > 10) return

    const offsetTop =
      (landingContainer.current?.offsetTop || 0) +
      (slides.current?.getBoundingClientRect().height || 0) * elIndex +
      70

    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth',
    })
  }

  return (
    <div className="grid grid-cols-12 gap-5" ref={landingContainer} data-gallery-landing>
      <div className="col-span-12 flex h-fit flex-col items-start gap-5 md:col-span-4 md:gap-10">
        {elements.map((element, index) => (
          <DzButtonNew
            asChild
            key={element._key}
            size="xxl"
            variant="link"
            className={cn(
              'whitespace-pre-wrap transition-none duration-200 hover:!text-black-100 focus-visible:text-black-100 active:underline active:decoration-black-100 md:!text-black-60 md:transition-colors',
              {
                '!text-black-100': activeImgId === element._key,
              }
            )}
            onMouseEnter={() => onMouseHover(index)}
          >
            <Link
              rel="noopener noreferrer"
              href={element.link.href}
              target={element.link.blank ? '_blank' : '_self'}
            >
              {element.title}
            </Link>
          </DzButtonNew>
        ))}
      </div>
      <div className="col-span-8 hidden aspect-[5/4] overflow-hidden md:block" ref={slides}>
        {elements.map((element) => {
          const { media } = dzMediaMapper({
            data: element.media,
            ImgElement: Image,
            options: {
              aspectRatio: MEDIA_ASPECT_RATIOS['AUTO'],
              imageContainerClassName: 'aspect-[5/4]',
            },
          })

          return (
            <DzMedia
              {...media}
              aspectRatio="aspectAuto"
              containerProps={{ id: element._key + suffix }}
              imageContainerClassName="h-full sticky top-0"
              movingImageContainerClassName="h-full !sticky top-0 [&_video]:!object-cover [&_video]:!h-full !flex"
              key={element._key}
            />
          )
        })}
      </div>
    </div>
  )
}
